@import url(https://fonts.googleapis.com/css?family=Mr+De+Haviland);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  color: #757575;
}

.App a {
  color: #00b2d3;
}

header {
  background-color: #fff;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pagetitle {
  background: #00b2d3;
}

.subtitle {
  font-family: 'Mr De Haviland', cursive;
 font-size: 2.92em  ;
}

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue-light {
  background-color: #76bdcc;
}

.winter {
  background-image: url(/static/media/winter.0662f318.jpg);
}

.cowbell {
  background-image: url(/static/media/ironman-cowbell.ccdfacfa.jpg);
}

.rocks {
  background-image: url(/static/media/hot-rocks.361e08dc.jpg);
}

.overlay {
  background-color: rgba(255, 255, 255, 0.55);
  color: #303030;
}

.services-icon {
  border: 2px #00b2d3 solid;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  color: #00b2d3;
  font-size: 2.5em;
  margin: 0 auto;
}

footer {
  background-image: url(/static/media/linen-dark.d03457eb.jpg);
  color: #fff;
}

