.App {
  color: #757575;
}

.App a {
  color: #00b2d3;
}

header {
  background-color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Mr+De+Haviland');

.pagetitle {
  background: #00b2d3;
}

.subtitle {
  font-family: 'Mr De Haviland', cursive;
 font-size: 2.92em  ;
}

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue-light {
  background-color: #76bdcc;
}

.winter {
  background-image: url('../src/assets/images/backgrounds/winter.jpg');
}

.cowbell {
  background-image: url('../src/assets/images/backgrounds/ironman-cowbell.jpg');
}

.rocks {
  background-image: url('../src/assets/images/backgrounds/hot-rocks.jpg');
}

.overlay {
  background-color: rgba(255, 255, 255, 0.55);
  color: #303030;
}

.services-icon {
  border: 2px #00b2d3 solid;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  color: #00b2d3;
  font-size: 2.5em;
  margin: 0 auto;
}

footer {
  background-image: url('../src/assets/images/backgrounds/linen-dark.jpg');
  color: #fff;
}
